import React from 'react';
import { Helmet } from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';

const SEO = ({ descripton, title, keywords, image, url, author }) => {
	return (
		<StaticQuery
			query={detailsQuery}
			render={(data) => {
				const metaDescription = descripton || data.site.siteMetadata.descripton;
				const metaTitle = title || data.site.siteMetadata.title;
				const metaAuthor = author || data.site.siteMetadata.author;
				const metaUrl = url || data.site.siteMetadata.url;
				const metaImage = image || data.site.siteMetadata.image;
				const metaKeywords = keywords || data.site.siteMetadata.keywords;
				return (
					<Helmet
						title={title}
						meta={[
							{
								name: `description`,
								content: metaDescription
							},
							{
								property: `og:title`,
								content: metaTitle
							},
							{
								property: `og:desription`,
								content: metaDescription
							},
							{
								property: `og:type`,
								content: `website`
							},
							{
								property: `og:image`,
								content: metaImage
							},
							{
								property: `og:url`,
								content: metaUrl
							},
							{
								name: `twitter:card`,
								content: ''
							},
							{
								name: `twitter:creator`,
								content: metaAuthor
							},
							{
								name: `twitter:title`,
								content: metaTitle
							},
							{
								name: `twitter:description`,
								content: metaDescription
							},
							{
								name: `twitter:image`,
								content: metaImage
							}
						].concat(
							metaKeywords && metaKeywords.length > 0
								? {
										name: `keywords`,
										content: metaKeywords.join(`, `)
									}
								: []
						)}
					/>
				);
			}}
		/>
	);
};

const detailsQuery = graphql`
	query DefaultSEOQuery {
		site {
			siteMetadata {
				title
				descripton
				keywords
				author
				url
				image
			}
		}
	}
`;

export default SEO;
